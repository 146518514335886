/* Promo
----------------------*/

.careers-scope {
    & .Author {
        & .Author-Image {
            flex: 0 0 75px;
            & img {
                /* Use .u-maxWidth100 utility once globally available */
                max-width: 100%;
            }
        }
    }
    & .Author-Container {
        background-color: rgb(224, 224, 225)
        /* TODO: when adel updates to 3.2 use var color opaque instead of hardCoded*/
        /* var(--color-neutral-15--opaque); */
    }
    & .blue-bg {
         & .vc-video-container{
            background-color: #003D6D;
          }
          & .video-description-container {
            background-color: #003D6D;
            & .video-name {
                color:white;
            }
            & .video-long-description{
                color:white;
            }
            & .video-duration{
                color:white;
            }
          }
          & a {
              color:white;
          }
    }
    & .gray-bg {
        & .vc-video-container{
           background-color: #F4F5F6;
         }
         & .video-description-container {
           background-color: #F4F5F6;
         }
         & .video-duration{
            color:#333;
        }
    }
    & .ear-brand {
        & .promo-content {
            & h2 {
                color: var(--color-brand);
            }
           & .midnight-bg {
                background-color: #132232;

                & .video-name {
                    color:white;
                }
                & .video-long-description {
                    color:white;
                }
                & .video-duration {
                    color:white;
                }
            }
        }
    }
    & .u-bgColorMidnightBlue {
        background-color: #132232;
        color:white;
        & .promo-content {
            & h2 {
                color: white;
            }
        }
        & .video-name {
            color: white !important;
        }
        & .video-long-description {
            color: white !important;
        }
        & .video-duration {
            color: white !important;
        }
    }
    & .u-bgColorBrand {
        & .promo-content {
            & h2 {
                color: white;
            }
        }
    }

    & .vc-video-container .video-js .vjs-big-play-button {
        top: 55% !important;
        left: 50% !important;
    }

    & .vc-video-container .video-js .vjs-big-play-button .vjs-icon-placeholder:before {
        top: -15% !important;
    }

    &  .vjs-volume-panel .vjs-volume-control.vjs-volume-vertical {
        bottom: 9em;
      }

      & .vjs-menu-button-popup .vjs-menu .vjs-menu-content {
        max-width: 6em !important;
        max-height: 8em !important;
      }
}
