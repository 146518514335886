.careers-scope {
	/* fix: Safari dropdown */
	& .Accordion-item {
		& .card-summary {
			width: 100%;
			padding: 10px;
		}
	}

	& .card-grid-component .Card, & .card-list-component .Card {
		& a[href$=".pdf"] {
			background-image: none;
			padding-right: 0;
		}
		
		& > a[href$=".pdf"] {
			&::before {
				display: none;
			}
		}

		& .view-article {
			opacity: 0;
			transition: all .5s;
			transform: translateY(20px);
		}

		/* hover transition for cards with links */
		&.card-link:hover {
			box-shadow: 0px 8px 16px var(--color-neutral-75);
			transition: all .5s;
		}
	}

    & .card-grid-component .Card .video-description-container h4 {
        margin-bottom: 18px;
        color: black;
    }

    & .card-grid-component .Card .video-description-container {
        padding-left: 20px;
        padding-right: 20px;
		padding-top: 25px;
		padding-bottom: 10px;
    }

	/* card-list stlyes */
	& .card-list-component {

		& #cardCategories {

			& details > summary {
				list-style: none;
			}

			& details {
				list-style: none;

				& summary {
					cursor: pointer;

					&::-webkit-details-marker {
						display:none;
          			}
				}

				& .Tooltip {
          transform: translateY(10%);
          min-width: 250px;
          display: none;
        }
        
        &[open] {
          & .Tooltip {
            display: block;
          }
        }
			}


			& summary.Accordion-toggle::before {
				display: none;
			}

			& summary span.Accordion-toggle::before {
				right: 0;
				left: auto;
			}
		}

		& .Card.featured {
			& .card-image {
				height: 400px;
			}
			& .card-image > div {
				height: 100%;
				background-position-x: center;
				background-repeat: no-repeat;
			}
		}

		& .Card {
			list-style: none;

			& .u-linkClean {

				& .card-image, 
				& .Content {
					cursor: pointer;
				}
			}
		}
	}   

	/* card grid styles */
	& .card-grid-component {

		& .Grid {
			& ul {
				display: contents;
			}

			& .Card--withFooter {
				display: block;
				height: 100%;
			}

			& .Card {

				&.featured-card {
					background-position: center center;
					background-repeat: no-repeat;
					& .card-content {
						position: absolute;
						bottom: 0;
						@include sm-viewport {
							position: relative;
						}
					}
				}

				& .card-article-wrapper {
					height: auto;
				}

				& .card-image {
					height: 10.938rem;

					@media sm-viewport {
							height: 12.5rem;
					}
				}
				& .card-image > div {
					height: 100%;
					border-radius: 1.7rem 1.7rem 0 0;
					background-position: center center;
					background-repeat: no-repeat;

					&.card-image-contain {
						background-size: contain;
					}
				}
				& .card-bottom {
					bottom: 0;
					left: 0;
					z-index: 10;
				}
				& .card-content {
					margin-top: auto;
					z-index: 10;
				}
				& .card-superheadline {
					color: rgba(47, 48, 51, .75);
					font-family: "Ameriprise Sans",Arial,sans-serif !important;
					-webkit-font-feature-settings: "kern" 1, "kern" !important;
					font-feature-settings: "kern" 1, "kern" !important;
					-webkit-font-kerning: normal !important;
					font-kerning: normal !important;
					font-size: 14px !important;
					font-weight: 600 !important;
					text-transform: uppercase !important;
					letter-spacing: .1em !important;
					line-height: 1.25 !important;
					margin-bottom: .4em !important;
					text-transform: uppercase !important;
				}
				& .u-linkClean {
					height: 100%;
					display: block;
					z-index: 100;
					position: relative;

					& .card-image, 
					& .Content {
						cursor: pointer;
					}
				}

				& .card-overlay {
					z-index: 1;
					height: 100%;
					background: linear-gradient(to bottom, transparent 0%, rgba(0,0,0,0.25) 100%);
				}
			}
		}

		& .collapse {
			visibility: collapse;
		}
	}

	/* card feature styles */
	& .card-grid-component.cardFeature {
		& .featured-card .card-content {
			border-bottom-left-radius: 1.7rem;
			border-bottom-right-radius: 1.7rem;
			width: 100%;
			@include sm-viewport {
				border-top-left-radius: 1.7rem;
				border-top-right-radius: 1.7rem;
			}
		}

		&.textBackground-white .featured-card {
			& .card-content {
				background-color: #FFFFFFCC;
			}

			@include sm-viewport {
				& .card-bottom {
					background-color: #FFFFFFCC;
				}
			}
		}

		&.textBackground-blue .featured-card {
			& .card-content {
				background-color: #003D6DB3;
			}

			@include sm-viewport {
				& .card-bottom {
					background-color: #003D6DB3;
				}
			}
		}

		&.textBackground-midnightBlue .featured-card {
			& .card-content {
				background-color: #132232B3;
			}

			@include sm-viewport {
				& .card-bottom {
					background-color: #132232B3;
				}
			}
		}

		&.textBackground-gray .featured-card {
			& .card-content {
				background-color: #313130BF;
			}

			@include sm-viewport {
				& .card-bottom {
					background-color: #313130BF;
				}
			}
		}

		@include sm-viewport {
			& .featured-card.Card.u-flex,
			& .featured-card.Card .u-flex {
				flex-direction: column;
			}
		}
	}

	& .vjs-menu-button-popup .vjs-menu .vjs-menu-content {
       display: block !important;
      }
}
