/* Callout
---------------------------*/
.careers-scope {
    & .Callout-text.Content {
        & h2,
        & h3,
        & h4,
        & h5,
        & h6 {
            margin-top: 0;
        }
    }

    /* Callout w/ graybox */
    & .Callout.has-graybox {

        /* Callout w/ icon & gray box */
        &.has-icon {
            height: 100%;
        }
    }

    & .TwoColumn .Callout {
        & .table-responsive {
            border: 0;
        }

        & .Callout-graybox, .Callout-test {
            & p {
                word-break: break-word;
            }
        }

        &.no-icon {

            & .Callout-graybox {
                min-height: 130px;
            } 
        }
    }
}