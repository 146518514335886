/* Accordion w/ background color */
.careers-scope {
 & .Accordion-component.Gray {

    & .Accordion-toggle {
        &:before {
            top: 16px;
        }
    }
    & h4 {
        font-style: normal;
    }
}
}