.careers-scope {
    & .pullquote-header{
        border-left: .3125rem solid var(--color-brand);
    }

    & .pullquote-header p {
        padding-left: 1.25rem;

        @media(max-width: 991px) {
            margin-top: .625rem;
        }
        @include sm-viewport {
            margin-top: .625rem;
        }
    }

    & .person-image-container {
        align-items: flex-end;
        display: flex;

        & img {
            margin-bottom: 4px;
        }
    }

    & .person p {
        color: var(--color-neutral-75);
    }

    & .person {
        @include sm-viewport {
            padding-top: 7px;
        }
    }
}
