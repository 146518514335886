.careers-scope {
    & .ear-brand{
       
        & h1 {
            font-family: 'Publico Headline Web';
            font-size: 3.125rem;
            letter-spacing: .5rem;
            line-height: 1.25;
            text-transform: uppercase;
        }
        & h2 {
            font-family: 'Publico Headline Web';
            font-size: 1.9375rem;
            letter-spacing: .4rem;
            line-height: 1.25;
            text-transform: uppercase;
        }
        & h3 {
            font-family: 'Publico Headline Web';
            font-size: 1.5625rem;
            font-weight: 300;
            letter-spacing: 0.02em;
            line-height: 1.9375rem;

            @include sm-viewport {
                line-height:2.5rem;
            }
        }
        & h4 {
            font-family: 'Amp Book';
            font-size: 1.375rem;
            line-height: 1.875rem;
            font-style: normal;

            @include sm-viewport {
                line-height:2.5rem;
            }
        }
        & p {
            font-family: 'Amp Book';
            font-size: 1rem;
            line-height: 1.5rem;

            @include sm-viewport {
                line-height:1.7rem;
            }
        }
    }
}