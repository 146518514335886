.careers-scope {
  & .amp-audio {
    width: auto;
  }

  & .amp-audio-title {
    padding-bottom: 15px;
  }
  
  & .amp-audio-description {
    padding-bottom: 15px;
  }
}