.careers-scope {
  & .HeroFeatures {
      margin-top: -90px !important;
  }

  & .ear-brand {
    & .HeroFeatures {
      @include sm-viewport {
        margin-top: -160px !important;
      }
    }
  }
}
