.careers-scope {
  & .Heading {
      color: rgba(47, 48, 51, 0.7);
      font-weight: 600;
      letter-spacing: 0.075em;
      margin-bottom: 20px;
      text-transform: uppercase;
  }

  & .Heading--reversed {
      color: rgba(255, 255, 255, 0.75);
  }

  & .Heading--withSeparator:after {
      border-bottom: 2px solid #007AB6;
      content: '';
      display: block;
      margin-top: 10px;
      width: 50px;
  }

  & .Heading--reversed.Heading--withSeparator:after {
      border-bottom-color: currentColor;
  }
}
