/* :root {
    --font-family-secondary: "Ameriprise Serif", "Times New Roman", serif;
} */

/* Tabbed Content
-------------------------*/
.careers-scope {

    & .Tabs {

        &.horizontal {
            & button[role="tab"] {
                &::after {
                    height: 3px;
                    bottom: -2px;
                    left: 0;
                    border-bottom: 3px solid var(--color-brand-light);
                }

                &[aria-selected="true"]::after {
                    width: 100%;
                }
            }
        }

        &.vertical {
            & button[role="tab"] {
                &::after {
                    width: 3px;
                    height: 0;
                    left: 0;
                    top: 0;
                    border-left: 3px solid var(--color-brand-light);
                }
                
                &[aria-selected="true"]::after {
                    height: 100%;
                }
            }

            & .Tab-panels {
                height: 100%;
            }
        }

        & button[role="tab"] {
            outline: none;
            transition: all .3s;

            &:hover,
            &:focus {
                color: var(--color-brand-light);
            }

            &::after {
                content: "";
                position: absolute;
                transition: width .3s;
            }
        } 
    }

    & .Tab-content .Promo-video {
        & .vc-video-container .video-js .vjs-big-play-button {
            background-color: var(--color-neutral-75);
        }
    }
}