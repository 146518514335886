/* Article 
----------------------*/
.careers-scope {
    & .Article {

        & button:not(.vjs-big-play-button),
        & button:not(.Button--circular),
        & .link-btn {
            color: #fff;
        }

        & .amp-audio-wrapper button {
            background-color: unset;
        }

        & .article-banner-bg {
            height: 350px;
            width: 100%;
            background-size: cover;
            background-repeat: no-repeat;

            @include sm-viewport {
                height: 250px;
            }

            &.has-intro {
                height: 350px;
            }
        }

        /* Accordion */
        & .Accordion .Content.section-content {
	        gap: 0px;
        }

        & .Content {
            & p em {
                white-space: break-spaces;
            }
            & p a {
                white-space: break-spaces; 
            } 
        }
        & .FourColumn {
            & h3 {
                color: var(--color-brand);
                padding-bottom: 20px;
                font-size: 22px;

                @include sm-viewport {
                    padding-bottom: 10px;
                }
            }
        }
        & .ThreeColumn{
            & h3 {
                color: var(--color-brand);
                padding-bottom: 20px;
                font-size: 22px;

                @include sm-viewport {
                    padding-bottom: 10px;
                }
            }
        }
        &.ear-brand {
            & .Content:not(.Content--reversed) h2 {
                color: var(--color-brand);
            }
        }
    }

	& .ear-brand {
        & .video-name {
            color: var(--color-black) !important;
        }
	}
}
