
.careers-scope {
  width: auto !important;
  
  & .Content {
    & img {
      height: auto;
      max-width: 100%;
    }
  }

  & .u-adel-reset {
    & h1, & h2, & h3, & h4, & h5, & h6 {
      & sup {
        font-size: 50%;
        top: -0.75em;
      }
    }
  }

  /* This will override the joinameriprise CSS * style which is set to black */
  /* This is needed for the banner component*/
   & .u-adel-reset .u-textColorWhite * {
        color: #fff !important
    }

   & .u-adel-reset .u-textColorDefault * {
        color: #2f3033 !important
    }

  & .Content--reversed {
    & h1, & h2, & h3, & h4, & h5, & h6, & p, & a, & label {
      color: #fff;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
    }
    & a:not(.Button) {
      text-decoration: underline;
    }
    & .Disclaimer-text > * {
      color: #fff;
    }
  }

  /* Useful when textColorDefault/Brand fails WCAG compliance */
  & .Content--black {
    & h1, & h2, & h3, & h4, & h5, & h6, & p, & div, & label {
      color: #000;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
    }
  }
  & .u-textColorBlack {
    color: #000;
  }

  & .u-bgColorSteeleBlue {
    background-color: rgb(93, 108, 122);
  }
  
  & .Input-label,
  & .Dropdown-label {
    font-weight: normal;
  }
  & strong {
      white-space: break-spaces;
  } 
}

/* This will override the outline border in the header component from ADEL */
.u-adel-reset careers-header:focus {
  outline: none !important;
}

/* This will override the outline border in the Nav menu bar component from ADEL */
.u-adel-reset careers-menu:focus {
  outline: none !important;
}

/* This will override the outline border in the Footer component from ADEL */
.u-adel-reset careers-footer:focus {
  outline: none !important;
}

/* This will override the outline border in the Footer disclaimer component from ADEL */
.u-adel-reset careers-footer-disclaimer:focus {
  outline: none !important;
}
