@import "../../../../../assets/css/partials/mixin-definition";

.careers-siteLogo-footer {
  @media (min-width: 64.063rem) {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
  @media (min-width: 75rem) {
    padding-left: 0;
    padding-right: 0;
  }
  & img {
    width: 12.5rem;

    @media (max-width: 64rem) {
      width: 7.813rem;
    }
  }
}

.careers-footerSocialAndDisclaimer {
  display: block !important;
  background: #f4f5f7;
  ul li {
    list-style: none;
  }
}

.careers-legalContent .careers-footerPrint {
  display: flex;
  justify-content: center;
  width: 100%;
}

.careers-legalContent .careers-footerPrint .careers-printLeftContainer {
  display: flex;
  flex-flow: column nowrap;
  flex: auto;
  p {
    margin: 15px 0;
  }
  ul {
    display: flex;
    margin: 15px 0;

    @include md-viewport {
      flex-direction: column;
    }  
  }
  & .careers-listItem {
    margin-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-left: 1px solid #d4dbe8;

    @include md-viewport {
      margin-top: 15px;
      margin-bottom: 15px;
      padding-left: 0;
      padding-right: 0;
      border-left: 0;
    }  
    & a {
      display: block;

      @include md-viewport {
        padding-left: 0 !important;
      }
    }
    & a:hover {
      text-decoration: underline;
    }
  }
  & ul li:first-child {
    border-left: none;
    padding-left: 0;
  }
  & ul li:first-child a {
    padding-left: 0 !important;
  }
  .careers-userAgreeTerms {
    @include md-viewport {
      margin-top: 0
    }
    & a:hover {
      text-decoration: underline;
    }
  }
}

.careers-legalContent .careers-footerPrint .careers-printRightContainer {
  display: flex;
  width: 90px;
  justify-content: center;
  & .norton-seal {
    margin: 15px 0 0 0;
    & img {
      width: 80px;
    }
  }
}

.careers-socialNetworkLinks {
  a {
    text-decoration: none !important;
  }
  @include md-viewport {
    flex-direction: column;
    justify-content: initial;
  }
  & .careers-socialLinks {
    width: 24%;
    
    @include md-viewport {
      padding-left: 20px;
      padding-right: 20px;
      width: 100%;
    }
  }
}

.Disclaimer p {
  font-size: 14px;
}


