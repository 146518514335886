.careers-scope {
    & .related-information-component {

        & .related-column-wrapper {

            & .column {
                /* overriding ADEL to account for different sized images */
                & .related-image-wrapper {
                    height: auto;
                
                    & img {
                        object-fit: cover;
                        height: 100%;
                    }
                }
            }
        }

        @include md-lg-viewport {
            & .related-column-wrapper .column .related-image-wrapper{
                width: auto;
                height: 115px;
            }
        }
    
        @include lg-viewport {
            & .related-column-wrapper .column .related-image-wrapper {
                width: auto;
                height: 200px;
            }
        }
    }
}
