.careers-scope {
    & .Featured-links {
        /* Horizontal - RTE */
        & ul {
            & li {
                @include md-lg-viewport {
                    display: inline-block;
                    padding-right: 10px;
                    margin-left: 8px;
                    border-right: 1px solid rgba(47,48,51,.4);

                    &:last-child {
                        margin-right: 0;
                        padding-left: 0;
                        border-right: none;
                    }
                }

                &:before {
                    content: '';
                }
            }
        }

        /* Icon */
        & .Callout-icon {
            max-width: 39px;
        }
    }
}
