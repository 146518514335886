.careers-scope {
	& .TileList--palette1 {
		& .Tile--1 {
			background-color: var(--color-cher);
		}

		& .Tile--2 {
			background-color: var(--color-adele);
		}

		& .Tile--3 {
			background-color: var(--color-duff);
		}
	}

	& .TileList--palette2 {
		& .Tile--1 {
			background-color: var(--color-adele);
		}

		& .Tile--2 {
			background-color: var(--color-beyonce);
		}

		& .Tile--3 {
			background-color: var(--color-duff);
		}
	}

	& .TileList--palette3 {
		& .Tile--1 {
			background-color: #132232;
		}

		& .Tile--2 {
			background-color: #07373F;
		}

		& .Tile--3 {
			background-color: #311B38;
		}
	}
	& .Tile {
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		& .Button {
				text-decoration: none;
		}

		& .Tile-content {

			& h2,
			& h3,
			& h4 {
				padding-bottom: 10px;
			}
		}
	}

	& .Tile:not(.Tile--reversed) {
		border: 1px solid var(--color-neutral-60);
	}

	/* COMPARISONS */

	& .TileList--comparisons {

		& .list-disclaimer p {
			font-size: var(--mod-scale-root-minus-1);
		}

		& .Tile {

			&.Tile--0 {
				& h3 {
					border-color: var(--color-white-75)!important;
				}

				& .list-content p {
	
					& .check-circle:before {
						color: var(--color-brand-lightest)
					}
				}
			}

			& .icon:before {
				font-family: "Icons";
				display: inline-block;
				font-style: normal;
				font-variant: normal;
				text-rendering: auto;
				-webkit-font-smoothing: antialiased;
				margin-right: 10px;
				vertical-align: middle;
			}

			& .list-content {

				& p {
					font-size: 16px;
					padding: 15px 0;
					margin-left: 30px;
					position: relative;

					& .gray-text {
						color: var(--color-neutral);
					}
				}

				& .icon.check-circle:before {
					content:'\E86C';
					font-size: 22px;
					color: var(--color-neutral-master-75);
					position: absolute;
					left: -30px;
					top: 12px;
				}
	
				& .icon.times-circle:before {
					content:'\e5c9';
					font-size: 22px;
					color: var(--color-neutral--opaque);
					position: absolute;
					left: -30px;
					top: 12px;
				}
			}

			& a {
				display: block;
				border-top: 1px solid var(--color-white-75);

				& .list-link {
					width: 90%;
				}

				&:before {
					content:'\E5C8';
					float: right;
					position: absolute;
					top: 23px;
					right: -10px;
				}

				&:hover {
					text-decoration: none;
					color: var(--color-white-75);
				}
			}
		}
	}
	& .ear-brand {
		& .Heading--reversed {
			color: rgba(255,255,255,0.75);
			letter-spacing: .0625rem ;
			line-height: .75rem;
		}
	}

	& .vc-video-container .video-js .vjs-big-play-button{
		top: 55% !important;
		left: 60% !important;
	}
}
