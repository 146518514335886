/* One column layout */
.careers-scope {
    & .Banner--primary {
        min-height: 225px;
        background-repeat: no-repeat;
        background-position: bottom left;
    }

    & .Banner--secondary {
        min-height: 225px;
    }

    & .Banner--credentials {
        & .image-block {
            width: 150px;
            margin: auto auto 20px;
        }
    }
    
    & .ear-brand {
        /* ear brand overrides */
        & .Content {
            & h1 {
                margin-top: 20px;
            }
            & h2 {
                margin-top: 20px;
            }
            & h3 {
                margin-top: 20px;
            }
        }
    }
}

@media (max-width: 50rem) {
    .careers-scope .ear-brand .Content table {
        width: 100%;
        & tbody { 
            width: 100%;
            display: flex;
            & tr {
                width: 100%;
                display: flex;
                flex-direction: column;
                & td {
                    width: auto !important;
                }
            }
        }
    }
}