.careers-scope {

    & .Categories {

        & .Category {

            & .Category-icon {

                & img {
                    width: 65px;
                }
            }

            & .Category-icon.u-flexJustifyBetween {

                & img {
                    transform: translateX(-6px);
                }
            }
        }
    }
}
