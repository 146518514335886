.careers-scope {
    & .Person-list {

        & .image-block img {
            width: auto !important;
            max-width: 100px;
            border-radius: 5px;
        }
    }
}
