.careers-scope {

  & .Hero-advisorText {
    margin-left: 230px;
  }

  & .HeroFeatures-promo {
    z-index: 15;
  }

  & .Hero-section-front{
    height: 250px;

    & .section-front-bg{
      height: 60%
    }

    & .section-front-heading {
      transform: translateY(-50%);
    }
  }

  & .Hero-topic-front {
    background-repeat: no-repeat;
    background-position: 50%;
    min-height: 250px;
  }

  & .Hero-front-section {

    & .section-front-bg{
      max-height: 250px;
    }

    & .section-front-heading {
      transform: translateY(-50%);
    }
  }

  & .Hero {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      & sup {
        font-size: 33%;
        top: -1.5em;
      }
    }

    .Button.Link--external:after {
      content: none;
    }
    
   
    @include sm-viewport {
      background-image: none;
      background-color:white !important;

      & h1 {
        word-break: break-word;
      }

      & img {
        margin-left: -50%;
        width: 150%;
      }
    }

    &.has-featured-promos {
      padding-bottom: 130px;
      padding-top: 100px;

      @include sm-viewport {
        padding-bottom: 80px;
      }
    }

    &.Hero--custom {
      border-bottom: 1px solid #2f303366;
    }
    
    & .Content--brand {
      & h1, & h2, & h3, & h4, & h5, & h6, & p, & a, & label {
         color: #003f6d;
      }
    }
  }

  & .ear-brand {
    & .Hero-simple {
      & h1 {
        @include sm-viewport {
          font-size: 2.125rem;
        }
      }
    }

    & .hero-content {
      & p {
        letter-spacing: .1rem;
        line-height: 19pt;
        font-family: 'Publico Headline Web';
        font-size: 1.5625rem;

        @include sm-viewport {
          color: #003f6d;
        }
      }
    }

    & h1,
    & h1.u-textColorWhite sup { /* overrides white text against light background */
      @include sm-viewport {
        color: #003f6d !important;
      }
    }

    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      & sup {
        letter-spacing: 0.25rem;
      }
    }
  }
}

@include md-lg-viewport {
  .careers-scope {
    & .Hero-topic-front img {
      height: auto !important;
      width: 100% !important;
    }
  }
}

@include sm-viewport {
  .careers-scope {
    & .component-wrapper:not(.ear-brand) {
      & .Hero-simple.Hero--Dark { /* white text for dark theme needs to be overridden against white background */
        & .hero-content.Content--reversed,
        & .hero-content.Content--reversed * {
          color: #2f3033 !important;
        }
        & h1.u-textColorWhite,
        & h1.u-textColorWhite * {
          color: var(--color-brand) !important;
        }
      }
    }
  }
}