.careers-scope {

    & .careers-carousel-component {

        & .swiper-wrapper {
            transition-duration: .5s;
        }

        & .image-block {
            display: flex;
        }

        & .arrows {
            top: 50%;
            cursor: pointer;

            &:before {
                font-family: "ADEL Material Symbols";
                font-size: 40px;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }                       

            &:focus {
                outline: auto;
                opacity: 1;
            }

            &:hover {
                opacity: 1;
            }

            &.swiper-button-next {
                right: 0;
            }

            &.swiper-button-prev {
                left: 0;
            }

            &.swiper-button-next,
            &.swiper-button-prev {
                @include sm-viewport {
                    bottom: 20px;
                    top: auto;

                    &:before {
                        font-size: 80px;
                    }
                }
            }
        }

        & .swiper-button-prev:focus,
        & .swiper-button-next:focus {
            outline: none !important;
        }

        & .swiper-button-next.swiper-button-disabled:hover {
            opacity: 0.25;
            cursor: not-allowed;
        }

        & .swiper-button-prev:before {
            content: "\E314";
        }

        & .swiper-button-next:before {
            content: "\e5cc";
        }

        & .swiper-pagination {
            bottom: 20px;
            right: 50%;
            transition: opacity .3s;
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
            z-index: 10;

            @include sm-viewport {
                bottom: 15px;
                margin-top: 15px;
            }

            & .swiper-pagination-clickable .swiper-pagination-bullet {
                cursor: pointer;
            }

            & .swiper-pagination-bullet {
                border-radius: 100%;
                width: 15px;
                height: 15px;
                background: var(--color-actionBlue-75);
                opacity: .3;
                margin-right: 10px;

                &:focus {
                    outline: none;
                }
                &:hover {
                    cursor: pointer;
                }
                
                &:last-child{
                    margin-right: 0;
                }
            }

            & .swiper-pagination-bullet-active {
                background: var(--color-brand-light);
                width: 15px;
                height: 15px;
                top: 1px;
                opacity: 1;
            }
        }
    }
}
