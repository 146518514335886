.careers-scope {

    & .cta {
        border-radius: 0.25rem;
        width: 75% !important;

        @include md-viewport {
            width: 100% !important;
        }

        & .cta-rule {
            color: rgba(255, 255, 255, 0.317);
            height: 0.125rem;
        }

        & .cta-link {

            & a {
                transition: 0.2s all ease-in-out;
                text-decoration: none;

                & span {
                    text-decoration: underline;
                }

                &::after {
                    content: '\E5C8';
                    font-family: "Icons";
                    font-size: 1rem;
                    display: block;
                    margin-bottom: 0.250rem;
                    margin-left: 0.313rem;
                    transition: 0.2s all ease-in-out;
                }

                &:hover,
                &:focus {

                    & span {
                        display: inline-block;
                    }

                    &::after {
                        transform: translateX(0.125rem);
                    }
                }

            }
        }
    }
}
