.careers-scope {
    & .quote.Content figure {
        margin-right: 30px;
        max-height: 160px;
        max-width: 160px;

        @include sm-viewport {
            margin-bottom: 20px;
        }
    }

    & .quote-content {
        width: 100%;

        & .quote-header p {
            font-family: "Ameriprise Serif", "Times New Roman", serif;
            font-size: 25px;
            line-height: 1;
        }

        & .person p {
            line-height: 1.5;
            margin-bottom: 0;
        }

        @include md-viewport {
            & .person {
                text-align: right;
            }
        }
    }

    /* EAR brand */
    & .ear-brand .quote-content {
        & .quote-header p {
            color: var(--color-brand);
            font-family: "Publico Headline Web", "Times New Roman", serif;;
            font-style: italic;
        }
    }
}