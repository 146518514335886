@import "../../../../../assets/css/partials/mixin-definition";

:host {
  background: white;
  display: block;
  position: relative;
  width: 100%;
}

/* Footer Navigation variables */
:root {
  --color-footer-hover: #333;
  --line-height-solid: 1;
  --color-footer-brand: #333;
  --footer-background: #F4F5F7;
  --footer-nav-border: #B2B8BA;
  --font-family-primary: "Ameriprise Sans", Arial, sans-serif;
  --font-family-secondary: "Ameriprise Serif", "Times New Roman", serif;
}

.careers-Footer {
  display: block !important;
  background: #F4F5F7;
}

.careers-FooterNavigation {
  font-family: "Ameriprise Sans", Arial, sans-serif;
  .careers-FooterNavLevelOne {
    display: flex;
    flex-wrap: wrap;
    a {
      display: block;
      text-decoration: none;
      color: #333;
      line-height: 1;
    }
    a:hover {
      text-decoration: none;
      color: #333;
    }
    .careers-FooterNavItemLevelOne {
      list-style: none;
      width: 33%;
      min-width: 33%;
      max-width: 33%;
      padding-top: 15px;
      padding-bottom: 15px;

      .careers-FooterNavItemChildLevelOne {
        font-weight: 600;
        padding-bottom: 5px;
        //margin-right: 20px;
      }
      .careers-FooterNavBorder {
        border-bottom: 1px solid #B2B8BA;
      }
      a, p { 
        font-size: 14px;
        line-height: 6px;
      }
    }
    .careers-FooterNavLevelTwo {
      list-style: none;
      margin: 0
    }
    .careers-FooterNavLevelTwo a {
      line-height: 1;
    }
    .careers-FooterNavLevelTwo, .careers-FooterNavLevelThree {
        padding-left: 0;
        padding-bottom: 0;
        .careers-FooterNavItemLevelTwo, .careers-FooterNavItemLevelThree {
          padding-left: 0;
          padding-top: 5px;
          padding-bottom: 0;
          & a:hover {
            text-decoration: underline;
          }
        }
    }
  }
}

@include md-viewport {
  .careers-FooterNavigation .careers-FooterNavLevelOne .careers-FooterNavItemLevelOne {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
}
