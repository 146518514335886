$sm-viewport-max-width: 47.99rem;
$md-viewport-min-width: 48rem;
$md-viewport-max-width: 64rem;
$lg-viewport-min-width: 64.01rem;
$lg-viewport-max-width: 75rem;

@mixin lg-viewport { /* large viewport per ADEL */
    @media screen and (min-width: $lg-viewport-min-width) {   
        @content;         
   }
}

@mixin md-lg-viewport { /* medium and large viewports following ADEL standards */
    @media screen and (min-width: $md-viewport-min-width) {   
        @content;         
   }
}

@mixin md-viewport { /* medium viewport per ADEL */
    @media screen and (min-width: $md-viewport-min-width) and (max-width: $md-viewport-max-width) {   
        @content;         
   }
}

@mixin sm-md-viewport { /* medium viewport per ADEL */
    @media screen and (max-width: $md-viewport-max-width) {   
        @content;         
   }
}

@mixin sm-viewport { /* small viewport per ADEL */
    @media screen and (max-width: $sm-viewport-max-width) {   
        @content;         
   }
}
