    /* ADEL .Content style rule too strict -- these styles account for nested elements that .Content > (element) does not reach */
.careers-scope{
    & .Content {
         & p img {
            width: auto;
        }

        & img[align="left"] {
            margin-right: 20px;
        }
        & h2 a,
        & h3 a,
        & h4 a,
        & h5 a,
        & h6 a {
            font-size: inherit;
            font-family: inherit;
        }

        & li p {
            display: contents;
        }

        &.checkmarks ul,
        &.checkmarks ol {
            padding-left: 0;
            list-style: none;

            & li::before {
                content: '\2714';
                margin-right: 15px;
                left: 0;
                position: relative;
            }

            & ul {
                padding-left: 50px;
                margin: 8px 0 20px;

                & li::before {
                    display: none;
                }
            }
        }
        /* font family variable not resolving -- added explicit font-family for backup */
        & .Disclaimer-text {
            font-family: var(--font-family-primary);
            font-family: "Ameriprise Sans", sans-serif;
        }
    }

    & .Table-compound {
        & .table-responsive{
            overflow-x: hidden;
            @media screen and (max-width: 768px){
                overflow-x: auto !important;
            }
        }

        & ul, & ol {
            & li {
                line-height: 1.25;
            }
        }

        & table {
            /* need to overwrite any table properties from RTE */
            width: 100%!important;
            border-collapse: collapse;
            table-layout: auto;
            border: 0;

            & th {
                font-size: 14px;
                font-weight: 600;
                line-height: 18px;
                border: 1px solid transparent;
                background: #dee2e5;
                padding: .9375rem .5rem;
                border-bottom: 1px solid var(--color-neutral-75);
                border-right: 1px solid var(--color-neutral);
                text-align: unset;
            }

            & tbody {

                & td {
                    vertical-align: top;
                    border-left: none;
                    border-right: none;
                    padding: .9375rem;
                    min-width: 6.25rem;
                    line-height: 1.25;
                    border-bottom: 1px solid var(--color-neutral);
                    border-right: 1px solid var(--color-neutral);

                    @include lg-viewport {
                        padding: 1.25rem 1.875rem 1.25rem .625rem;
                    }
                }
                & td:last-child {
                    border-right: none;
                }

                & tr:last-child td {
                    border-bottom: none;
                }

                & tr:first-child {
                    padding-top: 20px;
                }
            }

        }
    }

    & .Definition-list {

        & table {

            & tbody tr {

                & td:nth-child(1) {
                    width: 40%;
                }

                & td:nth-child(2) {
                    width: 60%;
                }
            }

            & td {
                border: 0;
            }
        }
    }

    & .pullquote-content > * {
        color:var(--color-neutral)
    }
}